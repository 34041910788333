import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

const sectionSlider = new Swiper('.js-slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    loop: true,
    speed: 1000,
    autoplay: {
        delay: 7500,
    },

    // Navigation arrows
    navigation: {
        nextEl: '.js-slider-button-next',
    },
});
